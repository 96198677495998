import React from "react";
import { useTranslation } from "react-i18next";

import Jumbotron from "../components/Jumbotron";
import Divider from "../components/Divider";

const SectionOne = () => {
  const { t } = useTranslation();
  return (
    <>
      <Jumbotron />
      <Divider title={t("divider-1")} showIcon />
    </>
  );
};

export default SectionOne;
