import React from 'react';
import { getI18n } from "react-i18next";

import './Impressum.scss';

const Impressum = () => {
  const currentLang = getI18n().language;

  if(currentLang == "en") {
    return (
      <div className="impressum-section" id="impressum-section">
        <div className="impressum-container">
          <h1 className="impressum-title">Imprint</h1>
          <div className="impressum-content">
            <p>Benjamin Dante Hinze<br />
            DESIRED.<br />
            Postfach 080325<br />
            10003 Berlin</p>

            <h2>Contact</h2>
            <p>Phone: +49-176-63816704<br />
            E-mail: info@desired-of.agency</p>

            <h2>VAT ID</h2>
            <p>Sales tax identification number according to Sect. 27 a of the Sales Tax Law:<br />
            DE269512982</p>

            <h2>EU dispute resolution</h2>
            <p>The European Commission provides a platform for online dispute resolution (ODR): <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/</a>.<br /> Our e-mail address can be found above in the site notice.</p>

            <h2>Dispute resolution proceedings in front of a consumer arbitration board</h2>
            <p>We are not willing or obliged to participate in dispute resolution proceedings in front of a consumer arbitration board.</p>
          </div>
        </div>
      </div>
    );
  }
  else {
    return (
      <div className="impressum-section" id="impressum-section">
        <div className="impressum-container">
          <h1 className="impressum-title">Impressum</h1>
          <div className="impressum-content">
            <p>Benjamin Dante Hinze<br />
            DESIRED.<br />
            Postfach 080325<br />
            10003 Berlin</p>
  
            <h2>Kontakt</h2>
            <p>Telefon: +49-176-63816704<br />
            E-Mail: info@desired-of.agency</p>
  
            <h2>Umsatzsteuer-ID</h2>
            <p>Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a Umsatzsteuergesetz:<br />
            DE269512982</p>
  
            <h2>EU-Streitschlichtung</h2>
            <p>Die Europ&auml;ische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/</a>.<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.</p>
  
            <h2>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
            <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
          </div>
        </div>
      </div>
    );    
  }
};

export default Impressum;
