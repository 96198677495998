import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import "./Topimage.scss";
import imgOne from "../../assets/images/pinky.webp";

const Banner = () => {
  const { t } = useTranslation();

  return (
    <div className="topimage">
      <div className="image-container">
        <div
          className={`parallax`}
          style={{ backgroundImage: `url(${imgOne})` }}
        ></div>
      </div>
    </div>
  );
};

export default Banner;
